/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 29, 2018 */
@font-face {
  font-family: 'andantino_scriptregular';
  src: url("../fonts/andantionscript-webfont.eot");
  src: url("../fonts/andantionscript-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/andantionscript-webfont.woff2") format("woff2"), url("../fonts/andantionscript-webfont.woff") format("woff"), url("../fonts/andantionscript-webfont.ttf") format("truetype"), url("../fonts/andantionscript-webfont.svg#andantino_scriptregular") format("svg");
  font-weight: normal;
  font-style: normal; }

*:after, *:before {
  display: block;
  line-height: 1; }

body {
  display: flex;
  height: 100vh;
  background-image: url(../img/img-21.jpg);
  background-repeat: repeat;
  position: relative;
  flex-direction: column;
  font-family: "Times New Roman", sans-serif;
  color: #414141;
  line-height: 1.2;
  margin: 0; }
  body.body {
    background-image: url(../img/img-23.jpg);
    background-repeat: repeat; }
  @media screen and (max-width: 1140px) {
    body {
      padding-top: 100px; } }
  @media screen and (max-width: 680px) {
    body {
      padding-top: 87px; } }

h1 {
  font-family: "Times New Roman", sans-serif;
  font-size: 38px;
  font-weight: bold;
  text-transform: uppercase;
  color: #b49157;
  line-height: 1;
  margin: 0 0 40px 0; }
  @media screen and (max-width: 1199px) {
    h1 {
      font-size: 32px;
      margin-bottom: 25px; } }
  @media screen and (max-width: 991px) {
    h1 {
      font-size: 28px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px; } }

h2 {
  font-family: "Times New Roman", sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #b49157;
  text-transform: uppercase;
  margin: 0 0px 40px; }
  h2 a {
    font-family: "Times New Roman", sans-serif;
    font-size: 36px !important;
    color: #b49157; }
    h2 a:hover {
      color: #414141;
      text-decoration: none; }
  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 30px;
      margin-bottom: 25px; }
      h2 a {
        font-size: 30px !important; } }
  @media screen and (max-width: 991px) {
    h2 {
      font-size: 26px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 22px; }
      h2 a {
        font-size: 24px !important; } }

h3 {
  font-family: "Times New Roman", sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #414141; }
  @media screen and (max-width: 1199px) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 991px) {
    h3 {
      font-size: 20px; } }

h4 {
  font-family: "Times New Roman", sans-serif;
  font-weight: bold;
  font-size: 22px;
  color: #414141; }
  @media screen and (max-width: 991px) {
    h4 {
      font-size: 20px; } }
  @media screen and (max-width: 767px) {
    h4 {
      font-size: 18px; } }

h5 {
  font-family: "Times New Roman", sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #414141; }

h6 {
  font-family: "Times New Roman", sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #414141; }

a {
  color: #414141;
  transition: 350ms;
  text-decoration: none; }
  a:focus {
    color: #414141;
    text-decoration: none; }
  a:hover {
    text-decoration: underline;
    color: #b49157; }

textarea,
button,
select,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"] {
  border-radius: 0px;
  -webkit-appearance: none; }

.main, .page {
  width: 1170px;
  position: relative;
  flex: 1 0 auto;
  font-size: 16px;
  line-height: 1.3;
  padding: 0 15px;
  margin: 0 auto; }
  .main textarea,
  .main input[type="text"],
  .main input[type="email"],
  .main input[type="search"],
  .main input[type="password"], .page textarea,
  .page input[type="text"],
  .page input[type="email"],
  .page input[type="search"],
  .page input[type="password"] {
    display: block;
    width: 100%;
    min-height: 40px;
    background-color: #f2f2f2;
    font-family: "Times New Roman", sans-serif;
    font-size: 16px;
    color: #414141;
    padding: 11px 20px;
    margin-bottom: 15px;
    border: none;
    line-height: 1;
    outline: none; }
    .main textarea::placeholder,
    .main input[type="text"]::placeholder,
    .main input[type="email"]::placeholder,
    .main input[type="search"]::placeholder,
    .main input[type="password"]::placeholder, .page textarea::placeholder,
    .page input[type="text"]::placeholder,
    .page input[type="email"]::placeholder,
    .page input[type="search"]::placeholder,
    .page input[type="password"]::placeholder {
      color: #999999; }
  @media screen and (max-width: 1170px) {
    .main, .page {
      width: 100%; } }

.main {
  background-color: rgba(255, 255, 255, 0.6); }
  .main .container {
    padding-top: 50px;
    padding-bottom: 50px; }
    @media screen and (max-width: 1170px) {
      .main .container {
        padding-top: 25px;
        padding-bottom: 25px; } }
    @media screen and (max-width: 768px) {
      .main .container {
        padding-top: 15px;
        padding-bottom: 15px; } }

.btn {
  display: table;
  height: 50px;
  width: auto !important;
  background-color: #b49157 !important;
  font-family: "Times New Roman", sans-serif !important;
  font-size: 16px;
  color: #fff !important;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0px;
  border: 2px solid #b49157;
  padding: 10px 45px !important;
  line-height: 1;
  outline: none !important;
  transition: 300ms; }
  .btn:focus, .btn:active {
    background-color: #b49157 !important;
    color: #fff; }
  .btn:hover, .btn.active {
    background-color: #fff !important;
    color: #b49157 !important; }
  @media screen and (max-width: 768px) {
    .btn {
      height: 40px;
      padding: 5px 25px !important; } }

a.btn {
  padding: 15px 35px !important; }
  @media screen and (max-width: 768px) {
    a.btn {
      height: 40px;
      padding: 10px 25px !important; } }

.check--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .check--list li {
    display: block;
    position: relative;
    font-size: 16px;
    padding-left: 25px;
    margin-bottom: 5px; }
    .check--list li:before {
      content: '\f00c';
      position: absolute;
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #000;
      left: 0;
      top: 0px; }

.circle--list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px; }
  .circle--list li {
    padding-left: 22px;
    position: relative;
    margin: 4px 0; }
    .circle--list li:after {
      content: '';
      width: 6px;
      height: 6px;
      background-color: #b49157;
      border-radius: 100%;
      position: absolute;
      left: 4px;
      top: 7px; }

.col--location,
.col--phone,
.col--email {
  position: relative;
  padding-left: 25px;
  font-size: 16px; }
  .col--location:after,
  .col--phone:after,
  .col--email:after {
    content: '';
    font-family: 'fontAwesome';
    font-size: 20px;
    color: #bc9c68;
    position: absolute;
    top: 0px;
    left: 0; }

.col--location:after {
  content: '\f041'; }

.col--phone:after {
  content: '\f095'; }

.col--email:after {
  content: '\f0e0';
  font-size: 16px;
  top: 2px; }

.col--email a {
  text-decoration: underline; }
  .col--email a:hover {
    text-decoration: none; }

.close {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
  opacity: 1;
  outline: none;
  z-index: 1; }
  .close:before, .close:after {
    content: '';
    width: 20px;
    height: 2px;
    background-color: #af8a52;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -1px auto 0;
    transition: 300ms; }
  .close:before {
    transform: rotate(45deg); }
  .close:after {
    transform: rotate(-45deg); }
  .close:hover {
    opacity: 1; }
    .close:hover:before {
      transform: rotate(135deg); }
    .close:hover:after {
      transform: rotate(45deg); }

.line {
  position: relative;
  z-index: 1; }
  .line:after {
    content: '';
    background: url("../img/line.png") no-repeat center top;
    background-size: contain;
    height: 15px;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: -5px;
    margin: 0 auto;
    z-index: -1; }

/* header */
header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  /* header phones */
  /* header phones */
  /* header search */
  /* header search */
  /* header logo */
  /* header logo */
  /* header nav */
  /* header nav */
  /* header langs */
  /* header langs */ }
  header .up {
    background-color: #f2ebdf;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3); }
  header .down {
    font-size: 0; }
  header .container {
    width: 1170px;
    padding: 0;
    position: relative; }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  header a {
    text-decoration: none; }
    header a:hover {
      text-decoration: none; }
  header .choose--btn {
    display: inline-block;
    vertical-align: top;
    height: 40px;
    width: 230px;
    position: relative;
    font-weight: bold;
    background-color: #c8af88;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    line-height: 1;
    padding: 11px 20px 11px 40px; }
    header .choose--btn:after {
      content: '\f100';
      font-family: 'fontAwesome';
      font-size: 20px;
      color: #fff;
      position: absolute;
      top: 8px;
      left: 38px;
      transition: 300ms; }
    header .choose--btn:hover:after {
      left: 30px; }
  header .slogan {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    font-size: 18px;
    color: #454545;
    margin: 8px 0 0 40px; }
  header .phones {
    position: relative;
    padding-left: 25px;
    margin: 10px 0 0 90px;
    display: inline-block;
    vertical-align: top; }
    header .phones:after {
      content: '\f095';
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #af8a52;
      position: absolute;
      left: 0;
      top: 2px; }
    header .phones ul li {
      display: inline-block;
      vertical-align: top; }
      header .phones ul li a {
        display: block;
        font-weight: bold;
        font-size: 16px;
        color: #454545;
        text-decoration: none; }
    header .phones ul span {
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      margin: 0px 5px 0; }
  header .search--btn {
    display: block;
    width: 18px;
    height: 18px;
    background-image: url(../img/search.png);
    background-size: contain;
    background-position: center center;
    position: absolute;
    top: 11px;
    right: 85px; }
  header .search {
    width: 0px;
    position: absolute;
    top: 5px;
    right: 80px;
    overflow: hidden;
    transition: 300ms;
    box-sizing: border-box;
    border: 1px solid transparent;
    z-index: 1; }
    header .search input[type="search"] {
      width: 100%;
      font-size: 16px;
      padding: 5px 30px 5px 15px;
      border: none;
      box-shadow: none;
      outline: none;
      line-height: 1; }
      header .search input[type="search"]::placeholder {
        font-style: italic; }
    header .search.open {
      width: 300px;
      background-color: #fff;
      border: 1px solid #af8a52; }
    header .search .close {
      top: 4px; }
  header .logo {
    display: inline-block;
    vertical-align: top;
    width: 230px;
    height: 100px;
    background-color: #f2ebdf;
    font-family: "andantino_scriptregular";
    font-size: 42px;
    color: #af8a52;
    position: relative;
    padding-top: 30px;
    text-align: center;
    box-sizing: border-box;
    margin: 5px 5px 0 0;
    line-height: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); }
    header .logo:after {
      content: '';
      background: url("../img/line.png") no-repeat;
      background-size: contain;
      height: 15px;
      position: absolute;
      left: 40px;
      right: 40px;
      bottom: 20px;
      margin: 0 auto; }
    header .logo span {
      display: block;
      position: relative;
      z-index: 1; }
  header .nav {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 235px);
    background-color: #ffffff;
    margin-top: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    text-align: justify;
    text-align-last: justify;
    padding: 0 10px; }
    header .nav > ul > li {
      display: inline-block;
      vertical-align: top;
      margin: 0 0px; }
      header .nav > ul > li > a {
        display: block;
        font-size: 16px;
        color: #454545;
        text-transform: uppercase;
        position: relative;
        padding: 39px 0; }
        header .nav > ul > li > a:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 5px;
          right: 5px;
          border-top: 0px solid #b49157;
          border-bottom: 0px solid #b49157;
          transition: 300ms;
          opacity: 0; }
        header .nav > ul > li > a:hover:before {
          border-top: 4px solid #b49157;
          border-bottom: 4px solid #b49157;
          opacity: 1; }
      header .nav > ul > li.active > a:before {
        border-top: 4px solid #b49157;
        border-bottom: 4px solid #b49157;
        opacity: 1; }
  header .langs {
    max-height: 22px;
    position: absolute;
    top: 7px;
    right: 15px;
    padding: 5px 22px 5px 5px;
    overflow: hidden;
    transition: 300ms;
    line-height: 1;
    z-index: 1; }
    header .langs:after {
      content: '\f107';
      font-family: 'fontAwesome';
      font-size: 16px;
      color: #af8a52;
      position: absolute;
      right: 5px;
      top: 4px; }
    header .langs ul li {
      margin-bottom: 4px; }
      header .langs ul li a {
        display: block;
        font-size: 16px;
        color: #454545;
        text-decoration: none; }
        header .langs ul li a:hover {
          color: #af8a52; }
      header .langs ul li.active a {
        text-decoration: none; }
    header .langs:hover {
      background-color: #af8a52;
      max-height: 150px; }
      header .langs:hover:after {
        color: #fff; }
      header .langs:hover ul li a {
        color: #fff; }
        header .langs:hover ul li a:hover {
          text-decoration: underline; }
      header .langs:hover ul li.active a {
        text-decoration: underline; }
  header.header {
    border-bottom: 2px solid #af8a52;
    background-color: rgba(255, 251, 246, 0.5); }
    header.header .slogan {
      display: block;
      font-size: 46px;
      font-weight: bold;
      color: #af8a52;
      text-align: center;
      text-transform: uppercase;
      outline: none;
      margin: 31px 0; }
    header.header .langs {
      max-height: 25px;
      top: 45px;
      right: 60px; }
      header.header .langs:after {
        top: 7px; }
      header.header .langs ul li a {
        color: #af8a52;
        font-size: 20px; }
      header.header .langs:hover {
        max-height: 150px; }
        header.header .langs:hover ul li a {
          color: #fff; }
  @media screen and (max-width: 1440px) {
    header.header .slogan {
      font-size: 34px; }
    header.header .langs {
      top: 35px; } }
  @media screen and (max-width: 1170px) {
    header .container {
      width: 100%; } }
  @media screen and (max-width: 1140px) {
    header {
      background-color: #f2ebdf;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); }
      header .up {
        background-color: #e1d3bc;
        box-shadow: none; }
      header .logo {
        display: table;
        height: auto;
        padding: 10px 0;
        box-shadow: none;
        margin: 0 auto; }
        header .logo:after {
          bottom: 0; }
      header .slogan {
        margin-left: 15px; }
      header .phones {
        margin-left: 35px; }
      header .nav {
        display: none;
        width: 100%;
        box-shadow: none;
        border-top: 2px solid #b49157;
        text-align: center;
        text-align-last: center;
        padding: 0; }
        header .nav > ul > li {
          display: block;
          margin: 8px 0; }
          header .nav > ul > li > a {
            display: table;
            padding: 0px 0px 8px;
            margin: 0 auto; }
            header .nav > ul > li > a:hover:before {
              border-top: 0px solid #b49157;
              border-bottom: 2px solid #b49157;
              opacity: 1; }
          header .nav > ul > li.active > a:before {
            border-top: 0px solid #b49157;
            border-bottom: 2px solid #b49157;
            opacity: 1; }
      header .nav--btn {
        display: block;
        width: 35px;
        height: 30px;
        position: absolute;
        left: 12px;
        top: 55px;
        padding: 0;
        margin: 0;
        outline: none;
        float: none;
        z-index: 1; }
        header .nav--btn span {
          position: absolute;
          left: 0;
          right: 0;
          width: 85%;
          height: 3px;
          margin: 0 auto;
          background-color: #af8a52;
          transition: 350ms; }
          header .nav--btn span:nth-of-type(1) {
            top: 7px; }
          header .nav--btn span:nth-of-type(2) {
            top: 14px; }
          header .nav--btn span:nth-of-type(3) {
            top: 21px; }
        header .nav--btn.open span:nth-of-type(1) {
          top: 14px;
          transform: rotate(45deg); }
        header .nav--btn.open span:nth-of-type(2) {
          opacity: 0; }
        header .nav--btn.open span:nth-of-type(3) {
          top: 14px;
          transform: rotate(-45deg); } }
  @media screen and (max-width: 991px) {
    header {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); }
      header .phones {
        display: none;
        background-color: rgba(200, 175, 137, 0.9);
        text-align: center;
        padding: 10px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
        margin: 0; }
        header .phones:after {
          display: none; }
        header .phones ul span {
          display: none; }
        header .phones ul li {
          display: block;
          margin: 5px 0; }
          header .phones ul li a {
            font-size: 18px; }
      header .phones--btn {
        display: block;
        font-family: 'fontAwesome';
        font-size: 22px;
        color: #af8a52;
        position: absolute;
        right: 15px;
        top: 58px;
        z-index: 1; }
        header .phones--btn:after {
          content: '\f095'; }
        header .phones--btn:hover {
          text-decoration: none; } }
  @media screen and (max-width: 680px) {
    header .choose--btn {
      width: 140px;
      height: 25px;
      font-size: 12px;
      text-align: left;
      padding: 7px 10px 6px 25px; }
      header .choose--btn:after {
        font-size: 15px;
        top: 4px;
        left: 8px !important; }
    header .slogan {
      font-size: 14px;
      margin: 5px 0px 0px 10px; }
    header .langs {
      right: auto;
      top: 27px;
      left: 5px;
      z-index: 2; }
    header .nav--btn {
      top: 50px;
      left: 7px; }
    header .phones--btn {
      top: 44px; }
    header .search--btn {
      width: 20px;
      height: 20px;
      top: 46px;
      right: 40px;
      z-index: 1; }
    header .search {
      top: 40px;
      right: 37px;
      z-index: 2; }
    header.header .langs {
      left: auto;
      right: 10px;
      top: 25px; }
    header.header .slogan {
      display: table;
      font-size: 26px;
      margin: 25px auto; } }
  @media screen and (max-width: 480px) {
    header .logo {
      height: 62px;
      width: 180px;
      font-size: 34px;
      padding-top: 12px; }
      header .logo:after {
        bottom: 7px; }
    header .choose--btn {
      width: 115px;
      font-size: 10px;
      padding: 8px 5px 7px 20px; }
      header .choose--btn:after {
        font-size: 12px;
        left: 5px !important;
        top: 6px; }
    header .slogan {
      font-size: 12px; }
    header .search {
      top: 87px;
      right: 0; }
      header .search.open {
        width: 100%; }
      header .search input[type=search] {
        padding: 7px 15px 4px 15px; }
    header.header .slogan {
      font-size: 18px;
      margin: 31px auto; } }

[data-lang="en"] header .choose--btn,
[data-lang="kz"] header .choose--btn {
  padding-left: 20px;
  padding-top: 13px;
  font-size: 13px; }
  [data-lang="en"] header .choose--btn:after,
  [data-lang="kz"] header .choose--btn:after {
    left: 16px; }

[data-lang="en"] header .nav > ul > li > a,
[data-lang="kz"] header .nav > ul > li > a {
  font-size: 13px; }

@media screen and (max-width: 680px) {
  [data-lang="en"] header .choose--btn,
  [data-lang="kz"] header .choose--btn {
    width: 100px;
    font-size: 10px;
    padding-top: 3px;
    text-align: right; } }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

.main {
  padding-top: 160px; }
  .main .block {
    position: relative;
    margin-bottom: 70px; }
    .main .block .calendar {
      width: 230px;
      position: absolute;
      top: 100px;
      right: 0; }
    @media screen and (max-width: 1140px) {
      .main .block .calendar {
        top: 55px; } }
    @media screen and (max-width: 991px) {
      .main .block {
        margin-bottom: 25px; }
        .main .block .calendar {
          top: 30px; } }
    @media screen and (max-width: 767px) {
      .main .block .calendar {
        top: 15px; } }
    @media screen and (max-width: 580px) {
      .main .block .calendar {
        width: 100%;
        position: relative;
        top: 0;
        margin: 15px 0; } }
  @media screen and (max-width: 1140px) {
    .main {
      padding-top: 0px; } }

/* main slider */
.slider {
  margin: 0 -15px 0px -15px !important; }
  .slider .slick-slide {
    overflow: hidden;
    position: relative;
    outline: none; }
    .slider .slick-slide img {
      display: block;
      width: 100%;
      height: auto; }
    .slider .slick-slide .slider__block {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      text-align: center;
      color: #fff;
      margin: -50px auto 0 auto; }
  .slider .slick-dots {
    bottom: 30px; }
    .slider .slick-dots li {
      width: auto;
      height: auto;
      margin: 0px 5px; }
      .slider .slick-dots li button {
        width: 18px;
        height: 18px;
        background-color: transparent;
        border-radius: 100%;
        border: 1px solid #fff;
        padding: 0;
        transition: 300ms; }
        .slider .slick-dots li button:before {
          display: none; }
        .slider .slick-dots li button:after {
          content: '';
          width: 8px;
          height: 8px;
          background-color: #fff;
          border-radius: 100%;
          box-sizing: border-box;
          margin: 0 auto;
          border: 1px solid #fff;
          transition: 300ms; }
        .slider .slick-dots li button:hover:after {
          background-color: #b49157; }
      .slider .slick-dots li.slick-active button {
        background-color: transparent; }
        .slider .slick-dots li.slick-active button:after {
          background-color: #b49157; }
  @media screen and (max-width: 991px) {
    .slider .slick-dots {
      bottom: 20px; } }
  @media screen and (max-width: 767px) {
    .slider .slick-slide img {
      width: auto;
      height: 380px; }
    .slider .slick-dots {
      bottom: 10px; } }
  @media screen and (max-width: 580px) {
    .slider .slick-slide img {
      width: 100%;
      height: auto; } }

/* main slider */
/* main about */
.about {
  padding: 0 15px; }
  .about h1 {
    display: table;
    font-family: "andantino_scriptregular";
    font-size: 60px;
    font-weight: normal;
    text-transform: none; }
    .about h1:after {
      height: 18px;
      bottom: -10px; }
  @media screen and (max-width: 991px) {
    .about {
      padding: 15px 0; } }
  @media screen and (max-width: 767px) {
    .about h1 {
      font-size: 45px;
      margin: 0 auto; } }

.about__img {
  max-width: 600px;
  background-color: #fff;
  float: right;
  padding: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  margin: -100px 0 45px 45px; }
  .about__img .img {
    width: 375px;
    background-color: #fff;
    position: absolute;
    left: 0;
    bottom: 15px;
    padding: 20px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
    transform: rotate(15deg); }
  .about__img img {
    display: block;
    width: 100%; }
  @media screen and (max-width: 1140px) {
    .about__img {
      max-width: 500px; }
      .about__img .img {
        width: 300px; } }
  @media screen and (max-width: 767px) {
    .about__img {
      max-width: 100%;
      width: 100%;
      padding: 15px;
      margin: 15px 0 25px; }
      .about__img .img {
        width: 60%;
        padding: 15px; } }
  @media screen and (max-width: 480px) {
    .about__img {
      padding: 10px; }
      .about__img .img {
        padding: 10px; } }

/* main about */
/* main cat */
.cat {
  padding: 50px 0; }
  .cat h2 {
    text-align: center; }
  .cat .row {
    margin: 0 -10px; }
    .cat .row .col-sm-4 {
      padding: 0 5px; }
  .cat .cat__col {
    display: block;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 30px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1), 0px 10px 30px rgba(0, 0, 0, 0.1); }
    .cat .cat__col .cat__col__img {
      display: block;
      height: 215px;
      margin: 0 0 15px 0;
      overflow: hidden; }
      .cat .cat__col .cat__col__img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: 300ms; }
    .cat .cat__col .cat__col__title {
      display: block;
      font-size: 32px;
      text-align: center;
      transition: 300ms; }
    .cat .cat__col .price {
      display: block;
      font-size: 26px;
      font-weight: bold;
      color: #b49157;
      text-align: center;
      margin: 5px 0 10px; }
      .cat .cat__col .price span {
        font-weight: normal;
        color: #414141; }
    .cat .cat__col p {
      min-height: 90px; }
    .cat .cat__col a {
      display: block;
      color: #414141; }
      .cat .cat__col a:hover {
        text-decoration: none; }
        .cat .cat__col a:hover .cat__col__title {
          color: #b49157; }
        .cat .cat__col a:hover .cat__col__img img {
          transform: scale(1.1); }
    .cat .cat__col .btn {
      display: table;
      margin: 35px auto; }
  @media screen and (max-width: 991px) {
    .cat {
      padding: 25px 0; }
      .cat .cat__col {
        padding: 20px; }
        .cat .cat__col .cat__col__title {
          font-size: 26px; }
        .cat .cat__col .price {
          font-size: 22px; } }
  @media screen and (max-width: 767px) {
    .cat {
      padding: 15px 0; }
      .cat .cat__col {
        margin: 15px 0; }
        .cat .cat__col p {
          min-height: 0px; }
        .cat .cat__col .btn {
          margin: 20px auto 0; } }

/* main cat */
/* main info */
.info {
  padding-bottom: 65px; }
  .info .info__col .info__col__title {
    display: block;
    min-height: 60px;
    background-color: #f2ebdf;
    font-weight: bold;
    padding: 15px 10px 5px 10px;
    line-height: 1.2;
    margin-bottom: 15px; }
  .info .row {
    margin: 0 -10px; }
    .info .row .col-sm-4 {
      padding: 0 5px; }
  @media screen and (max-width: 991px) {
    .info {
      padding-bottom: 40px; } }
  @media screen and (max-width: 767px) {
    .info {
      padding-bottom: 20px; }
      .info .info__col {
        margin: 15px 0; }
        .info .info__col .info__col__title {
          min-height: 0px;
          padding: 10px 10px 10px 10px; } }

/* main info */
/* main content */
/* page content */
.page {
  padding-top: 150px;
  padding-left: 0;
  padding-right: 0; }
  .page .news1 .date, .page .news .date {
    color: #b49157; }
  .page .news1 a, .page .news a {
    text-decoration: underline; }
    .page .news1 a:hover, .page .news a:hover {
      color: #b49157;
      text-decoration: none; }
  .page .page_nav .active {
    color: #b49157; }
  .page #ask-form {
    max-width: 350px; }
  .page .write {
    color: #af8a52;
    text-decoration: none; }
    .page .write:hover {
      text-decoration: underline; }
  .page .back_link {
    margin: 25px 0; }
  .page > .left, .page > .right {
    display: inline-block;
    vertical-align: top; }
  .page > .left {
    width: calc(100% - 240px); }
  .page > .right {
    width: 230px;
    float: right; }
  .page h1 {
    margin-bottom: 30px; }
  @media screen and (max-width: 1170px) {
    .page {
      font-size: 16px; }
      .page #photos-list td img {
        width: 100%;
        height: auto; } }
  @media screen and (max-width: 1140px) {
    .page {
      padding-top: 0; } }
  @media screen and (max-width: 991px) {
    .page > .left, .page > .right {
      display: block;
      width: 100%;
      float: none; }
    .page > .right {
      margin-top: 10px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 767px) {
    .page h1 {
      margin-bottom: 20px; } }
  @media screen and (max-width: 480px) {
    .page p img, .page p strong img {
      width: 100% !important;
      height: auto !important;
      display: block !important;
      float: none !important;
      margin: 0 0 10px 0 !important; }
    .page #photos-list td {
      width: 50%;
      display: inline-block;
      vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  font-size: 14px;
  padding: 22px 0; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .breadcrumbs ul li {
      padding: 5px 0px;
      color: #957546;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        text-decoration: none;
        color: #454545;
        font-size: 14px; }
        .breadcrumbs ul li a:hover {
          color: #957546; }
      .breadcrumbs ul li:after {
        content: '';
        width: 2px;
        height: 13px;
        background-color: #7f7d79;
        margin: 0px 5px 0px 10px;
        color: #454545;
        display: inline-block;
        vertical-align: top; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }
  @media screen and (max-width: 991px) {
    .breadcrumbs {
      padding: 15px 0; } }

/* breadcrumbs */
/* calendar */
.calendar {
  display: block;
  background-color: #e1d3bc;
  padding: 22px 25px;
  line-height: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); }
  .calendar h4 {
    font-size: 20px;
    text-transform: uppercase;
    color: #af8a52;
    margin: 0 0 10px; }
  .calendar > span {
    display: block;
    font-size: 16px;
    color: #454545;
    margin-bottom: 6px; }
  .calendar .dropdown {
    width: 100%;
    font-size: 14px;
    box-shadow: none !important;
    margin-bottom: 10px; }
    .calendar .dropdown .selected {
      min-height: 30px;
      padding: 8px 25px 8px 5px;
      box-shadow: inset 2px 3px 0px #f2f6f3; }
      .calendar .dropdown .selected:after {
        box-shadow: none; }
    .calendar .dropdown .carat {
      width: 10px;
      height: 8px;
      right: 8px;
      background: none; }
      .calendar .dropdown .carat:after {
        content: '';
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #af8a52;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto; }
    .calendar .dropdown div {
      border: 1px solid #af8a52;
      border-radius: 0px; }
      .calendar .dropdown div ul li {
        border-top: 1px solid #fff;
        padding: 8px 5px;
        box-sizing: border-box; }
        .calendar .dropdown div ul li.focus, .calendar .dropdown div ul li.active {
          background-color: #e1d3bc; }
        .calendar .dropdown div ul li:first-child {
          border-top: none; }
        .calendar .dropdown div ul li:last-child {
          padding-bottom: 10px; }
  .calendar .calendar__date {
    position: relative;
    margin-bottom: 10px; }
    .calendar .calendar__date:after {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      pointer-events: none;
      background: url(../img/img-20.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 8px;
      right: 5px; }
    .calendar .calendar__date input[type="text"] {
      min-height: 30px;
      background-color: #fff;
      font-size: 14px;
      padding: 7px 25px 6px 5px;
      box-shadow: inset 2px 3px 0px #f2f6f3;
      outline: none;
      margin: 0; }
  .calendar .btn {
    height: 44px;
    width: 100% !important;
    font-size: 14px;
    font-weight: bold;
    background-color: #ffffff !important;
    color: #af8a52 !important;
    border: 2px solid #fff !important;
    padding: 12px 20px !important;
    margin-top: 20px; }
    .calendar .btn:hover {
      background-color: #af8a52 !important;
      color: #fff !important;
      border: 2px solid #af8a52 !important; }

.ui-datepicker {
  border-radius: 0px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); }
  .ui-datepicker .ui-widget-header {
    background-color: #e1d3bc;
    border-radius: 0px; }
  .ui-datepicker .ui-datepicker-title {
    font-family: "Times New Roman", sans-serif;
    font-weight: bold; }
  .ui-datepicker .ui-state-default {
    background-color: #e1d3bc; }
  .ui-datepicker .ui-state-highlight {
    background-color: #ffeccc; }
  .ui-datepicker .ui-state-hover, .ui-datepicker .ui-state-active {
    background-color: #af8a52;
    border: 1px solid #af8a52;
    color: #fff; }
  .ui-datepicker .ui-datepicker-next,
  .ui-datepicker .ui-datepicker-prev {
    border: none;
    border-radius: 0px !important;
    transition: 300ms;
    cursor: pointer; }
    .ui-datepicker .ui-datepicker-next:hover,
    .ui-datepicker .ui-datepicker-prev:hover {
      background-color: transparent;
      border: none;
      border-radius: 0px !important;
      font-weight: normal; }
    .ui-datepicker .ui-datepicker-next:after,
    .ui-datepicker .ui-datepicker-prev:after {
      font-family: 'fontAwesome';
      font-size: 14px;
      color: #af8a52;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      transform: translateY(-50%);
      line-height: 1; }
  .ui-datepicker .ui-datepicker-next:after {
    content: '\f054'; }
  .ui-datepicker .ui-datepicker-next:hover {
    right: 2px;
    top: 2px; }
  .ui-datepicker .ui-datepicker-prev:after {
    content: '\f053'; }
  .ui-datepicker .ui-datepicker-prev:hover {
    left: 2px;
    top: 2px; }

/* calendar */
.right--contacts {
  min-height: 500px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 25px 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-top: 10px; }
  .right--contacts h4 {
    font-size: 20px;
    text-transform: uppercase;
    color: #af8a52;
    white-space: nowrap;
    margin: 0; }
  .right--contacts h6 {
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 15px;
    margin-top: 30px; }
  .right--contacts ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 15px; }
    .right--contacts ul li {
      display: block;
      padding-left: 18px;
      margin: 14px 0; }
      .right--contacts ul li:after {
        font-size: 18px;
        font-weight: bold; }
      .right--contacts ul li a:hover {
        text-decoration: none; }
  @media screen and (max-width: 991px) {
    .right--contacts {
      min-height: 0px;
      padding: 25px 20px; }
      .right--contacts h6 {
        margin-top: 20px; } }

.content {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 35px 30px 80px;
  /* page catalog */
  /* page catalog */
  /* page contacts */
  /* page contacts */ }
  .content h5 {
    margin: 15px 0; }
  .content .content__slider--for {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 220px);
    margin-bottom: 20px; }
    .content .content__slider--for .slick-slide {
      height: 250px;
      width: 100%;
      outline: none; }
      .content .content__slider--for .slick-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
    .content .content__slider--for .slick-arrow {
      width: 32px;
      height: 17px;
      background: #b49157 url(../img/arrow.png) no-repeat center center;
      background-size: 15px;
      z-index: 1;
      transition: 300ms; }
      .content .content__slider--for .slick-arrow:before {
        display: none; }
      .content .content__slider--for .slick-arrow.slick-prev {
        left: 10px;
        transform: rotate(180deg) translate(0, 50%); }
      .content .content__slider--for .slick-arrow.slick-next {
        right: 10px; }
      .content .content__slider--for .slick-arrow:hover {
        opacity: 0.7; }
  .content .content__slider--nav {
    display: inline-block;
    vertical-align: top;
    width: 210px;
    float: right;
    margin-top: -5px; }
    .content .content__slider--nav .slick-slide {
      height: 120px;
      width: 100%;
      outline: none;
      position: relative;
      margin: 5px 0;
      cursor: pointer; }
      .content .content__slider--nav .slick-slide:after {
        content: '';
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 1;
        transition: 300ms; }
      .content .content__slider--nav .slick-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
      .content .content__slider--nav .slick-slide:hover:after, .content .content__slider--nav .slick-slide.slick-current:after {
        opacity: 1; }
  .content .cat {
    font-size: 0;
    padding: 0;
    text-align: center; }
    .content .cat .row .col-sm-4 {
      padding: 0 12px; }
    .content .cat .row .col-md-4 {
      display: inline-block;
      vertical-align: top;
      float: none; }
    .content .cat .cat__block {
      text-align: left;
      font-size: 16px; }
      .content .cat .cat__block .cat__block__title {
        display: block;
        background-color: #f2ebdf;
        font-size: 24px;
        text-transform: uppercase;
        text-align: center;
        padding: 12px;
        line-height: 1; }
      .content .cat .cat__block .cat__block__for {
        margin-bottom: 10px; }
        .content .cat .cat__block .cat__block__for .slick-slide {
          height: 175px;
          outline: none; }
          .content .cat .cat__block .cat__block__for .slick-slide img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center; }
      .content .cat .cat__block .cat__block__nav {
        margin: 0 -7px 25px; }
        .content .cat .cat__block .cat__block__nav .slick-slide {
          height: 60px;
          position: relative;
          outline: none;
          margin: 0 7px;
          cursor: pointer; }
          .content .cat .cat__block .cat__block__nav .slick-slide:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.5);
            transition: 300ms;
            opacity: 0; }
          .content .cat .cat__block .cat__block__nav .slick-slide:hover:after {
            opacity: 1; }
          .content .cat .cat__block .cat__block__nav .slick-slide.slick-current:after {
            opacity: 1; }
          .content .cat .cat__block .cat__block__nav .slick-slide img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center; }
        .content .cat .cat__block .cat__block__nav .slick-arrow {
          width: 32px;
          height: 17px;
          background: #b49157 url(../img/arrow.png) no-repeat center center;
          background-size: 15px;
          z-index: 1;
          transition: 300ms; }
          .content .cat .cat__block .cat__block__nav .slick-arrow:before {
            display: none; }
          .content .cat .cat__block .cat__block__nav .slick-arrow.slick-prev {
            left: 0;
            transform: rotate(180deg) translate(0, 50%); }
          .content .cat .cat__block .cat__block__nav .slick-arrow.slick-next {
            right: 0; }
          .content .cat .cat__block .cat__block__nav .slick-arrow:hover {
            opacity: 0.7; }
      .content .cat .cat__block .price {
        display: block;
        font-size: 26px;
        text-align: center;
        margin-bottom: 20px; }
        .content .cat .cat__block .price span {
          font-weight: bold;
          color: #b49157; }
      .content .cat .cat__block ul {
        list-style: none;
        margin: 0;
        padding: 0;
        min-height: 420px;
        margin-top: 20px; }
      .content .cat .cat__block .cat__block__list li {
        min-height: 30px;
        position: relative;
        padding: 0 15px 0 45px;
        margin: 3px 0; }
        .content .cat .cat__block .cat__block__list li img {
          display: block;
          width: 35px;
          height: 35px;
          object-fit: contain;
          object-position: center top;
          position: absolute;
          left: 0;
          top: 0; }
      .content .cat .cat__block .btn {
        padding: 15px 25px !important;
        margin: 35px auto 0; }
  .content .about__img {
    max-width: 430px;
    margin-top: 0;
    margin-bottom: 60px; }
    .content .about__img .img {
      max-width: 275px;
      bottom: -25px; }
  .content .about__list {
    list-style: none;
    margin: 0;
    padding: 0; }
    .content .about__list li {
      min-height: 50px;
      position: relative;
      padding-left: 55px;
      margin: 20px 0; }
      .content .about__list li img {
        width: 45px;
        height: 40px;
        object-fit: contain;
        object-position: top center;
        position: absolute;
        top: 0;
        left: 0; }
      .content .about__list li strong {
        display: block;
        margin-bottom: 5px; }
  .content .about__block a {
    display: block;
    background-color: #f5f5f5;
    padding: 15px;
    margin: 25px 0;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
    overflow: hidden; }
    .content .about__block a img {
      display: block;
      width: 100%;
      transition: 300ms; }
    .content .about__block a:hover img {
      transform: scale(1.05); }
  .content .circle--list li {
    padding-left: 30px;
    margin: 25px 0; }
  .content .contacts ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 30px; }
    .content .contacts ul li {
      margin: 20px 0; }
      .content .contacts ul li a {
        display: block; }
  .content .contacts h3 {
    font-size: 26px;
    font-weight: normal;
    margin: 0 0 20px 0; }
  .content .contacts .left h3 {
    padding-left: 25px; }
  .content .contacts .right h3 {
    text-align: center; }
  .content .contacts .map {
    width: 100%;
    height: 355px; }
    .content .contacts .map > ymaps, .content .contacts .map .ymaps-2-1-69-map {
      width: 100% !important;
      height: 100% !important; }
  .content .contacts form {
    display: block; }
    .content .contacts form .g-recaptcha {
      display: table;
      margin: 10px auto; }
    .content .contacts form .btn {
      margin: 15px auto 0; }
  @media screen and (max-width: 991px) {
    .content {
      padding: 25px 20px 40px; }
      .content .cat .row .col-md-4 {
        display: block; }
      .content .cat .cat__block {
        margin-bottom: 25px; }
        .content .cat .cat__block .cat__block__title {
          font-size: 20px; }
        .content .cat .cat__block .cat__block__for .slick-slide {
          height: 300px; }
        .content .cat .cat__block .cat__block__nav .slick-slide {
          height: 120px; }
        .content .cat .cat__block .price {
          font-size: 20px; }
        .content .cat .cat__block .btn {
          margin-top: 25px; }
        .content .cat .cat__block ul {
          min-height: 0px; }
      .content .contacts h3 {
        font-size: 22px; }
      .content .contacts .left h3 {
        padding-left: 0; }
      .content .contacts .map {
        height: 250px;
        margin-bottom: 15px; } }
  @media screen and (max-width: 767px) {
    .content .content__slider--for {
      width: calc(100% - 150px); }
      .content .content__slider--for .slick-slide {
        height: 200px; }
    .content .content__slider--nav {
      width: 140px; }
      .content .content__slider--nav .slick-slide {
        height: 95px; }
    .content .cat .cat__block .btn {
      padding: 10px 25px !important; } }
  @media screen and (max-width: 580px) {
    .content .cat .cat__block .cat__block__for .slick-slide {
      height: 250px; }
    .content .cat .cat__block .cat__block__nav .slick-slide {
      height: 100px; } }
  @media screen and (max-width: 480px) {
    .content .content__slider--for {
      width: 100%; }
    .content .content__slider--nav {
      display: block;
      width: auto;
      float: none;
      margin: 0 -10px 20px; }
      .content .content__slider--nav .slick-slide {
        margin: 0 10px; }
    .content .cat .cat__block .cat__block__for .slick-slide {
      height: 200px; }
    .content .cat .cat__block .cat__block__nav .slick-slide {
      height: 80px; }
    .content .about__list li {
      padding-left: 35px; }
      .content .about__list li img {
        width: 30px;
        height: 30px; } }
  @media screen and (max-width: 380px) {
    .content .content__slider--nav .slick-slide {
      height: 80px; }
    .content .cat .cat__block .cat__block__for .slick-slide {
      height: 180px; }
    .content .cat .cat__block .cat__block__nav .slick-slide {
      height: 70px; }
    .content .contacts form .g-recaptcha {
      width: 250px;
      transform: scale(0.8);
      transform-origin: 0% 50%;
      margin: 10px 0; } }

/* page hostel */
.hostel {
  height: calc(100vh - 230px);
  padding: 135px 55px 25px; }
  .hostel .hostel__col {
    display: block;
    position: relative;
    font-size: 0;
    margin: 15px 0;
    box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.4); }
    .hostel .hostel__col:before {
      content: '';
      border: 7px solid #fefaf5;
      position: absolute;
      top: 20px;
      left: -22px;
      right: 22px;
      bottom: -20px; }
    .hostel .hostel__col .box {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      z-index: 1; }
      .hostel .hostel__col .box img {
        display: block;
        width: 100%;
        height: 100%; }
    .hostel .hostel__col .hostel__col__block {
      width: 50%;
      height: calc(50vh - 170px);
      font-size: 18px;
      font-weight: bold;
      background-color: #fffbf6; }
      .hostel .hostel__col .hostel__col__block .hostel__col__block__title {
        display: table;
        font-family: "andantino_scriptregular";
        font-size: 55px;
        font-weight: normal;
        color: #af8a52;
        white-space: nowrap;
        padding-top: 25px;
        margin: 0 auto 30px; }
      .hostel .hostel__col .hostel__col__block ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: table;
        position: relative;
        margin: 0 auto;
        z-index: 2; }
        .hostel .hostel__col .hostel__col__block ul li {
          margin: 10px 0; }
          .hostel .hostel__col .hostel__col__block ul li a {
            display: block;
            font-size: 18px; }
      .hostel .hostel__col .hostel__col__block h4 {
        font-weight: bold;
        font-size: 22px;
        color: #414141;
        text-align: center;
        text-transform: uppercase;
        padding-top: 25px; }
    .hostel .hostel__col .hostel__col__img {
      width: 50%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0; }
      .hostel .hostel__col .hostel__col__img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: 300ms; }
    .hostel .hostel__col .btn {
      display: block;
      width: 220px !important;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 28px;
      padding: 15px 10px !important;
      margin: 0 auto;
      z-index: 2; }
    .hostel .hostel__col:hover .hostel__col__img img {
      transform: scale(1.1); }
    .hostel .hostel__col.last {
      filter: grayscale(100%);
      pointer-events: none; }
  @media screen and (max-width: 1440px) {
    .hostel {
      height: auto; }
      .hostel .hostel__col .hostel__col__block {
        height: 250px; }
        .hostel .hostel__col .hostel__col__block .hostel__col__block__title {
          font-size: 35px;
          margin-bottom: 10px; }
      .hostel .hostel__col .btn {
        bottom: 10px; }
      .hostel .col-md-4 .hostel__col .hostel__col__block {
        width: 65%;
        position: relative; } }
  @media screen and (max-width: 1140px) {
    .hostel {
      padding: 20px 30px; } }
  @media screen and (max-width: 991px) {
    .hostel .hostel__col {
      margin: 0 0 30px; }
      .hostel .hostel__col .hostel__col__block {
        padding-bottom: 50px; }
      .hostel .hostel__col .btn {
        height: auto;
        padding: 10px 15px !important; }
    .hostel .col-md-4 .hostel__col .hostel__col__block {
      width: 50%; } }
  @media screen and (max-width: 580px) {
    .hostel {
      padding: 40px 20px 10px; }
      .hostel .hostel__col:before {
        top: 15px;
        left: -15px;
        bottom: -15px;
        right: 15px; } }
  @media screen and (max-width: 480px) {
    .hostel {
      padding-top: 30px; }
      .hostel .hostel__col .box {
        top: 4px;
        right: 4px;
        bottom: 4px;
        left: 4px;
        border: 2px solid #b49157; }
        .hostel .hostel__col .box img {
          display: none; }
      .hostel .hostel__col .hostel__col__img {
        width: 100%;
        position: relative; }
        .hostel .hostel__col .hostel__col__img img {
          height: auto; }
      .hostel .hostel__col .hostel__col__block {
        width: 100% !important;
        height: auto;
        padding-bottom: 70px; }
      .hostel .hostel__col .btn {
        bottom: 25px; } }

/* page hostel */
/* modal */
.modal .modal-dialog {
  top: 45%;
  margin: 0 auto;
  transform: translateY(-50%) !important; }

.modal .modal-content {
  width: auto;
  max-width: 420px;
  border-radius: 0px;
  margin: 0 auto; }
  .modal .modal-content h4 {
    font-family: "Times New Roman", sans-serif;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    padding: 0 15px;
    margin: 0; }

.modal form {
  display: block;
  padding-bottom: 140px;
  margin-top: 25px; }
  .modal form textarea,
  .modal form input[type="text"],
  .modal form input[type="email"],
  .modal form input[type="search"],
  .modal form input[type="password"] {
    display: block;
    width: 100%;
    min-height: 40px;
    background-color: #f2f2f2;
    font-family: "Times New Roman", sans-serif;
    font-size: 16px;
    color: #414141;
    padding: 11px 20px;
    margin-bottom: 15px;
    border: none;
    line-height: 1;
    outline: none; }
    .modal form textarea::placeholder,
    .modal form input[type="text"]::placeholder,
    .modal form input[type="email"]::placeholder,
    .modal form input[type="search"]::placeholder,
    .modal form input[type="password"]::placeholder {
      color: #999999; }
  .modal form .g-recaptcha {
    float: right; }
  .modal form .btn {
    float: right;
    margin: 15px 0 0 0; }

@media screen and (max-width: 767px) {
  .modal .modal-content h4 {
    font-size: 16px; } }

@media screen and (max-width: 420px) {
  .modal .modal-dialog {
    margin: 0 10px; }
    .modal .modal-dialog h4 {
      font-size: 16px; } }

@media screen and (max-width: 380px) {
  .modal .modal-content form .g-recaptcha {
    transform: scale(0.85);
    transform-origin: 100% 50%; } }

.call--btn {
  position: fixed;
  bottom: 100px;
  right: 35px;
  display: block;
  background-color: #af8a52;
  font-family: 'FontAwesome';
  border-radius: 100%;
  text-decoration: none !important;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 25px 5px 5px 5px;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.7);
  outline: none;
  z-index: 2; }
  .call--btn:hover {
    animation-name: phone;
    animation-iteration-count: 1;
    animation-duration: 0.7s; }
  .call--btn i {
    display: block;
    font-size: 34px;
    color: #fff; }
  .call--btn:after, .call--btn:before {
    content: '';
    width: 90%;
    height: 90%;
    background-color: transparent;
    border: 1px solid #af8a52;
    position: absolute;
    top: 5%;
    left: 5%;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-name: ring; }
  .call--btn:after {
    animation-delay: 0.6s; }
  .call--btn:before {
    animation-delay: 0.8s; }
  @media screen and (max-width: 1140px) {
    .call--btn {
      bottom: 30px;
      right: 20px; } }
  @media screen and (max-width: 991px) {
    .call--btn {
      width: 50px;
      height: 50px;
      padding-top: 13px;
      right: 15px;
      bottom: 30px; }
      .call--btn i {
        font-size: 24px; } }

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0.1;
    transform: scale(1.7); } }

@keyframes phone {
  20% {
    transform: rotate(-30deg); }
  80% {
    transform: rotate(390deg); }
  100% {
    transform: rotate(360deg); } }

/* modal */
.btn--up {
  display: block;
  background-color: #af8a52;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  border: 2px solid #af8a52;
  position: fixed;
  bottom: 25px;
  right: 30px;
  padding: 5px 10px;
  z-index: 1;
  line-height: 1;
  opacity: 0; }
  .btn--up:focus {
    color: #fff; }
  .btn--up:hover {
    background-color: #fff;
    text-decoration: none;
    color: #af8a52;
    opacity: 0.7; }
  @media screen and (max-width: 1140px) {
    .btn--up {
      display: none; } }

/* page content */
/* footer */
footer {
  background-color: rgba(180, 145, 87, 0.2);
  font-size: 16px;
  padding-top: 55px; }
  footer > .container {
    padding-bottom: 50px; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    footer ul li {
      margin: 0 0 10px 0; }
      footer ul li a {
        display: block; }
        footer ul li a:hover {
          text-decoration: none; }
      footer ul li.active a {
        color: #b49157; }
  footer h6 {
    margin: 0 0 30px; }
  footer .footer--about {
    padding-right: 150px; }
    footer .footer--about .footer__logo {
      display: table;
      font-family: "andantino_scriptregular";
      font-size: 42px;
      color: #af8a52;
      margin: 0 0 50px 0; }
    footer .footer--about .socials {
      display: table;
      padding-top: 40px;
      border-top: 1px solid #c6ab82;
      line-height: 1;
      margin-top: 35px; }
      footer .footer--about .socials ul li {
        display: inline-block;
        vertical-align: top;
        margin: 0 30px 0 0; }
        footer .footer--about .socials ul li a {
          display: block;
          width: 40px;
          height: 40px;
          font-size: 20px;
          color: #b49157;
          text-align: center;
          border: 1px solid #b49157;
          box-sizing: border-box;
          padding: 8px 0 0 0; }
          footer .footer--about .socials ul li a i.fa-facebook {
            margin-top: 2px; }
          footer .footer--about .socials ul li a:hover {
            background-color: #fff; }
  footer .footer--contacts ul li {
    margin-bottom: 25px; }
    footer .footer--contacts ul li a {
      margin-bottom: 3px; }
  footer .container-fluid .container {
    border-top: 1px solid #cab187;
    padding: 35px 0; }
  footer .container-fluid p {
    display: inline-block;
    vertical-align: top;
    margin: 0; }
  footer .container-fluid .artmedia {
    display: inline-block;
    vertical-align: top;
    position: relative;
    float: right;
    padding-right: 130px; }
    footer .container-fluid .artmedia:hover {
      text-decoration: none; }
    footer .container-fluid .artmedia img {
      position: absolute;
      top: -4px;
      right: 0; }
  footer.footer {
    background-color: rgba(255, 251, 246, 0.5);
    border-top: 2px solid #af8a52;
    padding: 35px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
    footer.footer .container-fluid .container {
      position: relative;
      border: none;
      padding: 0 15px; }
      footer.footer .container-fluid .container p, footer.footer .container-fluid .container .artmedia {
        color: #af8a52; }
      footer.footer .container-fluid .container .artmedia {
        margin-top: 8px; }
      footer.footer .container-fluid .container .socials {
        width: 250px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        text-align: center;
        margin: 0 auto;
        transform: translateY(-50%); }
        footer.footer .container-fluid .container .socials ul li {
          display: inline-block;
          vertical-align: top;
          margin: 0 18px; }
          footer.footer .container-fluid .container .socials ul li a {
            display: block;
            width: 40px;
            height: 40px;
            font-size: 20px;
            text-align: center;
            color: #af8a52;
            border: 1px solid #af8a52;
            padding-top: 6px;
            box-sizing: border-box; }
            footer.footer .container-fluid .container .socials ul li a:hover {
              text-decoration: none;
              background-color: #fff; }
            footer.footer .container-fluid .container .socials ul li a .fa-facebook {
              margin-top: 3px; }
  @media screen and (max-width: 1440px) {
    footer.footer {
      position: relative;
      padding: 20px 0; } }
  @media screen and (max-width: 1199px) {
    footer .footer--about {
      padding-right: 35px; } }
  @media screen and (max-width: 991px) {
    footer .footer--about {
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    footer {
      padding-top: 25px; }
      footer > .container {
        padding-bottom: 25px; }
      footer h6 {
        margin-bottom: 15px; }
      footer .footer--about .footer__logo {
        margin-bottom: 15px; }
      footer .footer--about .socials {
        padding-top: 20px;
        margin: 25px 0; }
        footer .footer--about .socials ul li {
          margin-right: 20px; }
      footer .footer--contacts ul li {
        margin-bottom: 15px; }
      footer .container-fluid .container {
        padding: 20px 15px; }
      footer.footer .container-fluid .container p {
        display: block;
        text-align: center; }
      footer.footer .container-fluid .container .socials {
        position: relative;
        transform: translateY(0);
        margin: 15px auto; }
        footer.footer .container-fluid .container .socials ul li {
          margin: 0 10px; }
      footer.footer .container-fluid .container .artmedia {
        float: none;
        display: table;
        margin: 0 auto; } }
  @media screen and (max-width: 680px) {
    footer .container-fluid .container p {
      display: block;
      text-align: center; }
    footer .container-fluid .container .artmedia {
      display: table;
      float: none;
      margin: 10px auto 0; } }

/* footer */
